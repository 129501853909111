import { memo, useEffect } from 'react'
import { useCustomerContext } from '@/context/CustomerContext'
import { useModalContext } from '@/context/ModalContext'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import IconCheckmark from '@/svgs/checkmark.svg'
import Checkbox from 'react-custom-checkbox'
import { GiftOrder } from '../GiftOrder'

const GiftRecipient = ({ accountFormType = 'default' }) => {
  const { customer: data, logout } = useCustomerContext()
  const { data: cartData, allSubsInCartAreGiftable } = useHeadlessCheckoutContext()
  const modalContext = useModalContext()
  const hasGiftCard = cartData?.cart?.lineItems?.some(
    (item) => item.variant.product.handle === 'digital-gift-card'
  );

  return (
    <MemoizedCustomer
      customer={data}
      cart={cartData?.cart}
      logout={logout}
      modalContext={modalContext}
      accountFormType={accountFormType}
      hasGiftCard={hasGiftCard}
      hasOnlyGiftSubscriptions={allSubsInCartAreGiftable}
    />
  )
}

const MemoizedCustomer = memo(
  ({ cart, accountFormType: aft, hasGiftCard, hasOnlyGiftSubscriptions }) => {
    const { updateOrderMetaData } =
      useHeadlessCheckoutContext()

      const isGiftOrder = cart?.properties?.is_gift_order == 'true'

      const orderHasGifts = hasGiftCard || isGiftOrder

      useEffect(() => {
        if (hasGiftCard && isGiftOrder) {
          updateOrderMetaData([{ key: 'is_gift_order', value: 'true' }])
        }
      }, [hasGiftCard, cart?.properties?.is_gift_order])

    const toggleIsGiftOrder = () => {
      updateOrderMetaData([
        {
          key: 'is_gift_order',
          value: cart.properties.is_gift_order == 'true' ? 'false' : 'true',
        },
      ])
    }

    return (
      <div className="order-info">
        <div className="order-customer">
          <div
            className={`checkout__header checkout__header--border-on-closed checkout__row checkout__header--open`}
          >
            <h3>Enter Gift Recipient Info</h3>
        
          </div>
          <>
            <div className="checkout__checkbox-wrapper tooltip">
              <Checkbox
                data-testid={'is-gift-order-checkbox'}
                className="checkout__checkbox"
                icon={
                  <div className="checkbox--checked">
                    <IconCheckmark />
                  </div>
                }
                label={
                  <h6>This order is a gift shipping directly to the recipient</h6>
                }
                checked={orderHasGifts}
                onChange={toggleIsGiftOrder}
                disabled={hasGiftCard || !hasOnlyGiftSubscriptions}
              />
              {hasGiftCard && (
              <span className="tooltiptext">
                You have gift cards in your cart. Please complete your gift
                order before unselecting the option.
              </span>
            )}
            {!hasOnlyGiftSubscriptions && (
              <span className="tooltiptext">
                You have non-gift subscriptions in your cart. Please remove them before selecting the option.
              </span>
            )}
            </div>
            {isGiftOrder && (
              <GiftOrder
                cart={cart}
                updateOrderMetaData={updateOrderMetaData}
              />
            )}
          </>
        </div>
      </div>
    )
  },
)

export default GiftRecipient
