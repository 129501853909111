import Checkbox from "react-custom-checkbox";
import { formatShopifyPrice } from '@/utils/formatPrice';
import { DateTime } from "luxon";
import { EmptyState } from "../../EmptyState";
import { LoadingState } from "../../LoadingState";

export const ShippingLineList = ({
  loading,
  shippingLines,
  selectedShippingLine,
  onChange,
  disabled,
  selectedStandardShipWeek,
}) => {
  if (!!loading) {
    return <LoadingState/>
  }

  if (!shippingLines || shippingLines.length < 1) {
    return <EmptyState
      title="Enter a shipping address to see shipping options"
      icon="box"
    />
  }

  const displayedShippingLines = shippingLines.map(li => {
    if (li.title === 'Ship with Next Order') {
      li.estimatedDeliveryDateDisplay = DateTime.fromISO(li.estimatedDeliveryDate).toFormat('EEEE, MMM d')
      li.display = (<>{`Delivered as soon as ${li.estimatedDeliveryDateDisplay}`}<sup>{`\u2020`}</sup></>)
    }
    if (li.title === 'Expedited Shipping') {
      li.estimatedDeliveryDateDisplay = DateTime.fromISO(li.estimatedDeliveryDate).toFormat('EEEE, MMM d')
      li.display = (<>{`Delivered as soon as ${li.estimatedDeliveryDateDisplay}`}<sup>{`\u2020`}</sup></>)
    }
    return li
  })

  return (
    <>
      <div className="shipping-method-selector">
        {displayedShippingLines && displayedShippingLines.map((method, index) => {
          const lineSelected = selectedShippingLine?.title === method.title
          let label
          let extraOptions

          if (method.title?.includes('Standard Shipping')) {
            if (!method.options) return
            extraOptions = (
              <div className={`secondary-shipping-method-selector ${lineSelected ? 'is-visible' : ''}`}>
                {method.options.map((o, i) => {
                  const shipWeekOptionSelected = (selectedStandardShipWeek === o.shipWeekPreference) && lineSelected
                  const estimatedDeliveryDateDisplay = DateTime.fromISO(o.estimatedDeliveryDate).toFormat('EEEE, MMM d')
                  return (
                    // eslint-disable-next-line react/no-unknown-property
                    <div key={i} className={`checkout__secondary-radio-wrapper ${shipWeekOptionSelected ? 'is-selected' : 'is-visible'}`}>
                      <Checkbox
                        className="checkout__radio"
                        icon={<div className="radio--checked"></div>}
                        label={<div className="checkout__secondary-radio-label"><span>{`Delivered as soon as ${estimatedDeliveryDateDisplay}`}<sup>{`\u2020`}</sup></span></div>}
                        name="standard-shipping-option"
                        checked={shipWeekOptionSelected}
                        disabled={disabled || !lineSelected}
                        onChange={() => onChange({ line: method, shipWeek: o.shipWeekPreference, estimatedDeliveryDateDisplay })}
                      />
                    </div>
                  );
                })}
              </div>
            )

            label = (
              <div>
                <div className="checkout__radio-label">
                  <span>{method.title}<sup>{`\u002A`}</sup></span>
                  <span>{`$${formatShopifyPrice(method.price, true)}`}</span>
                </div>
              </div>
            );
          } else {
            const secondaryLabel = (
              <div className="checkout__secondary-radio-label">
                <span>{method.display}</span>
              </div>
            );

            label = (
              <div>
                <div className="checkout__radio-label">
                  <span>{method.title}</span><span>{`$${formatShopifyPrice(method.price, true)}`}</span>
                </div>
                {secondaryLabel}
              </div>
            );
          }

          return (
            // eslint-disable-next-line react/no-unknown-property
            <div key={index}>
              <div className={`checkout__radio-wrapper ${lineSelected ? 'is-selected' : 'is-visible'}`}>
                <Checkbox
                  className="checkout__radio"
                  icon={<div className="radio--checked"></div>}
                  label={label}
                  name="shipping-method"
                  checked={lineSelected}
                  disabled={disabled || lineSelected}
                  onChange={() => onChange({ line: method, shipWeek: method.shipWeekPreference, estimatedDeliveryDateDisplay: method.estimatedDeliveryDateDisplay })}
                />
              </div>
              {extraOptions}
            </div>
          );
        })}
      </div>
      <div className="shipping-method-footnote">
        <p><sup>{`\u002A`}</sup>Unless a preferred ship date is selected, orders placed by Saturday at midnight will ship next week for delivery by Friday</p>
        <p><sup>{`\u2020`}</sup>Exact delivery calculated after purchase</p>
      </div>
    </>
  );
};
