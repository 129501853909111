import { useState } from 'react'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import StartStep from './Steps/StartStep'
import ErrorStep from './Steps/ErrorStep'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { captureException } from '@sentry/nextjs'

const SinglePageLayout = () => {
  const { data: checkoutData } = useHeadlessCheckoutContext()
  const [component, setComponent] = useState(<StartStep />)

  const backToStartStep = () => {
    setComponent(<StartStep />)
  }

  function errorIsHandled(e) {
    const handledFields = ['discountCode', 'giftCardCodes', 'quantity']
    const ignoredCodes = ['INVALID_DELIVERY_OPTION']
    return (
      e.field.some((f) => handledFields?.includes(f)) ||
      ignoredCodes?.includes(e.code)
    )
  }

  const unhandledCheckoutErrors =
    checkoutData?.userErrors?.filter((e) => !errorIsHandled(e)) || []

  useDeepCompareEffect(() => {
    if (unhandledCheckoutErrors && unhandledCheckoutErrors.length > 0) {
      console.error('cart errors: ', unhandledCheckoutErrors)
      unhandledCheckoutErrors.forEach((error) =>
        captureException(new Error(JSON.stringify(error))),
      )
      setComponent(
        <ErrorStep
          backToStartStep={backToStartStep}
          errors={unhandledCheckoutErrors}
        />,
      )
    }
  }, [checkoutData])

  return component
}

export default SinglePageLayout
