import { useState, useEffect } from 'react'
import { InputField } from '../InputField';

const GiftOrder = ({ cart, updateOrderMetaData }) => {
  const { attributes } = cart

  function getAttribute(attribute) {
    return attributes.find(att => att.key === attribute)?.value
  }

  const [email, setEmail] = useState(getAttribute('recipient_email') || '')
  const [name, setName] = useState(getAttribute('recipient_name') || '')
  const [message, setMessage] = useState(getAttribute('gift_message') || '')

  /* 
  TODO: the onBlur call to updateOrderMetaData here is causing weird issues 
  where you may be typing in a field after tabbing out of another and the 
  field will get cleared - ZJ
  */

  useEffect(() => {
    setEmail(getAttribute('recipient_email') || '')
    setName(getAttribute('recipient_name') || '')
    setMessage(getAttribute('gift_message') || '')
  }, [cart?.attributes])

  return (
    <div className="order-giftnote-form">
      <div className="input-group--wrapper">
        <InputField
          className="input"
          placeholder="recipient@email.com"
          type="email"
          name="recipient_email"
          autoComplete="email"
          label="Recipient Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => updateOrderMetaData([
            {key: 'recipient_email', value: email},
            {key: 'recipient_name', value: name},
            {key: 'gift_message', value: message}
          ])}
        />
        <InputField
          className="input"
          placeholder="Recipient Name"
          type="text"
          name="recipient_name"
          label="Recipient Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() => updateOrderMetaData([
            {key: 'recipient_email', value: email},
            {key: 'recipient_name', value: name},
            {key: 'gift_message', value: message}
          ])}
        />
      </div>
      <InputField
        className="input"
        placeholder="Gift message"
        type="textarea"
        name="gift_message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onBlur={() => updateOrderMetaData([
            {key: 'recipient_email', value: email},
            {key: 'recipient_name', value: name},
            {key: 'gift_message', value: message}
          ])}
      />
      <p>We will notify the gift recipient within 1-2 days of purchase. Your recipient will receive separate delivery emails</p>
    </div>
  );
};

export default GiftOrder;