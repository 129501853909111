import { useRouter } from 'next/router'
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext'
import LoadingState from '@/components/LoadingState'
import CheckoutSwitch from './CheckoutSwitch'
import ContinueShoppingButton from '../ContinueShoppingButton/ContinueShoppingButton'

const CheckoutContent = ({ data }) => {
  const router = useRouter()
  const { closeCartFlyout, isLoading, setIsLoading, initializeCart } =
    useHeadlessCheckoutContext()

  if (isLoading) {
    return (
      <div className="checkout__loading-state" role="main">
        <LoadingState />
      </div>
    )
  }

  if (data?.cart?.lineItems.length > 0) {
    return (
      <div className="checkout__cart">
        <CheckoutSwitch />
      </div>
    )
  } else if (
    typeof window !== 'undefined' &&
    !navigator.cookieEnabled
  ) {
    return (
      <div className="checkout__header-main checkout__header-main--empty-cart">
        <h4>Cookies Disabled</h4>
        <p>
          You have cookies disabled on your browser. Please re-enable cookies to
          add items to your cart and checkout.
        </p>
      </div>
    )
  } else {
    return (
      <div className="checkout__header-main checkout__header-main--empty-cart">
        <h4>Your Cart Is Empty</h4>
        <ContinueShoppingButton data={data} />
      </div>
    )
  }
}

export default CheckoutContent
